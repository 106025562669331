.k-multiselect {
  &:focus,
  &.k-focus {
    border-color: initial;

    &:after {
      content: none;
    }
  }
}

.k-list-item:focus,
.k-list-optionlabel:focus,
.k-list-item.k-focus,
.k-focus.k-list-optionlabel {
  box-shadow: none;
}
