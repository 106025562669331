.table {
    caption-side: bottom;
    border-collapse: collapse;

    thead tr:last-child {
        border-bottom: 1px solid #D2D0CE;
    }

    &.table-striped tbody tr:nth-of-type(odd) {
        background-color: #F3F2F1;
    }
}

.table-sm {

    // stylelint-disable-next-line selector-max-universal
    > :not(caption)>*>* {
        padding: 0.25rem 0.25rem;
    }
}