@mixin line-item {
  border-radius: 1px;
  width: 11px;
  height: 5px !important;
  display: inline-block;
  margin-right: 8px;
  position: relative;
  top: -1px;
}

:global {
  :local(.icon) {
    width: 100%;
    height: 100%;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;

    &.type-FireHydrant {
      background-image: url(./fire-hydrant.svg);
    }

    &.type-TimeLimit {
      background-image: url(./sign-parking.svg);
    }

    &.type-NoParking {
      background-image: url(./sign-no-parking.svg);
    }

    &.type-Reserved {
      background-image: url(./sign-handicap.svg);
    }

    &.type-Curb {
      @include line-item();
      background-color: #000000;
      height: 3px;
    }

    &.type-CurbCut {
      @include line-item();
      background-color: #a020f0;
      height: 5px;
    }

    &.type-Pole {
      background-image: url(./pole.svg);
    }

    &.type-EVChargingStation {
      background-image: url(./ev_charger.svg);
    }
  }
}
