.icon {
  border-radius: 1px;
  width: 11px;
  height: 5px !important;
  display: inline-block;
  margin-right: 8px;
  position: relative;
  top: -1px;

  &.type-Enforcement {
    background-color: #0038ff;
  }

  &.type-Planning {
    background-color: #ff0017;
  }

  &.type-Management {
    background-color: #05ff00;
  }
}
