div.mapboxgl-popup {
  div.mapboxgl-popup-content {
    padding: 0;
  }

  button.mapboxgl-popup-close-button {
    color: transparent;
    background: transparent
      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>")
      center/1em auto no-repeat;
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    transform: scale(0.9);

    top: 10px;
    right: 10px;
    opacity: 0.5;

    &:hover {
      text-decoration: none;
      opacity: 0.75;
    }
  }

  .map-popup-header {
    padding: 12px 10px 8px 14px;
    position: relative;
    min-height: 63px;

    background: $headerbackground;
    border-bottom: $headerBorder;
    color: $textColorLight;

    * {
      color: $textColorLight;
    }

    h6,
    h5,
    h4,
    h3,
    h2,
    h1 {
      margin-bottom: 0;
    }

    h4 {
      font-size: 16px;
      line-height: 22px;
      min-height: 22px;
      font-weight: 600;

      padding-right: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;

      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .sign-icon,
    .meter-icon,
    .spot-icon {
      position: absolute;
      width: 36px;
      height: 40px;
      top: 15px;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .offstreet-zone-icon,
    .zone-icon {
      position: absolute;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .header-description {
      color: $textColorLight;
      font-size: 12px;
    }
  }

  div.map-popup-body {
    padding: 15px;
    color: $textColorLight;
  }

  .pic-info {
    font-size: 10px;
    color: $textColorLight;
    line-height: 12px;

    .state-updated {
      color: $textColor;
    }
  }

  .spinner {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
}

div.camera-popup {
  .mapboxgl-popup-content {
    width: 100%;
    height: 100%;
  }

  .map-popup-body {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

div.meter-popup,
div.sign-popup {
  .mapboxgl-popup-content {
    width: 340px;

    &.loading {
      height: 330px;
    }
  }
}

div.sign-popup {
  .mapboxgl-popup-content {
    width: 660px;
  }
}

div.custom-layer-popup {
  .mapboxgl-popup-content {
    width: 660px;
  }
}

div.spot-popup {
  .mapboxgl-popup-content {
    width: 600px;

    &.loading {
      height: 330px;
    }
  }
}

div.zone-popup {
  .mapboxgl-popup-content {
    width: 684px;
  }
}

div.enforcement-popup {
  .mapboxgl-popup-content {
    width: 410px;
  }
}

div.offstreet-zone-popup {
  .mapboxgl-popup-content {
    width: 684px;
  }
}

div.area-popup {
  .mapboxgl-popup-content {
    width: 496px;
  }

  app-panel-collapse {
    padding: 0 0 0 20px;
    border-bottom: none;

    h4 {
      font-size: 14px;
      color: #605e5c;
    }
  }
}

div.street-sign-popup {
  .mapboxgl-popup-content {
    width: 360px;
  }
}

div.meter-popup {
  .mapboxgl-popup-content {
    width: 340px;
  }
}

div.revenue-zone-popup,
div.revenue-offstreet-zone-popup {
  .mapboxgl-popup-content {
    width: 684px;
  }
}

div.parking-duration-popup {
  .mapboxgl-popup-content {
    width: 400px;
  }
}

div.loading-zone-popup {
  .mapboxgl-popup-content {
    width: 830px;
  }
}

div.loading-zone-popup-qr-code-only {
  .mapboxgl-popup-content {
    width: 530px;
  }
}

div.blockface-popup {
  .mapboxgl-popup-content {
    width: 684px;
  }
}

div.study-area-popup {
  .mapboxgl-popup-content {
    width: 684px;
  }
}

div.revenue-tooltip {
  .mapboxgl-popup-content {
    width: auto;
  }
}

div.heatmap-tab {
  min-height: 300px;
}

.map-popup-row {
  display: flex;
  flex-direction: row;
  margin-top: 6px;
  margin-bottom: 6px;

  >label {
    width: 50%;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: $textColorLight;
  }

  .row-value {
    width: 50%;
    font-size: 14px;
    line-height: 22px;
    color: $textColor;
  }

  &.more-info {
    justify-content: right;
    gap: 30px;
    margin-bottom: 0;
    margin-top: 10px;

    position: relative;

    .refreshInfo {
      position: absolute;

      left: 5px;
      bottom: 0px;
    }
  }
}

.no-content {
  line-height: 22px;
  font-style: italic;
  font-size: 12px;
  color: #a19f9d;
}

.cluster-item {
  align-items: center;
  padding: 1px;

  &:hover {
    background-color: #f5faff;
  }

  * {
    cursor: pointer;
  }

  label {
    width: 23%;
  }

  .cluster-item-icon {
    width: 14px;
    height: 14px;
    margin-right: 14px;

    border-radius: 50%;
  }
}