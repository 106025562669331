.body {
  background: white;
  position: relative;
  flex: 1;

  :global(.ph-item) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  :global(.ph-item) {
    padding-top: 10px;
    border: none;
    flex-flow: wrap;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
    overflow-y: hidden;
  }

  :global(.ph-row) {
    flex-flow: wrap;

    > div {
      height: 15px;
      margin-right: 15px;
      margin-bottom: 15px;
    }
  }
}
