.section {
  margin-bottom: 20px;

  .title {
    color: #323130;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }

  .content {
    margin-left: 20px;
  }

  .plainContent {
    margin-top: 10px;
  }
}
