.assets-page {
  display: flex;
  background-color: #fff;
  overflow-x: hidden;

  .nav-sidebar {
    min-width: 150px;
    border-right: 1px solid #edebe9;

    .k-panelbar-group .k-panelbar-item,
    .k-panelbar-header {
      .k-link {
        color: initial;

        &.k-selected {
          background-color: #edebe9;
        }

        &:hover {
          background-color: #edebe9;
        }
      }
    }
  }

  .content {
    padding-left: 20px;
    padding-top: 20px;
    width: calc(100% - 150px);
  }
}
