.menuDemoContainer {
  display: flex;

  .menuButton {
    font-size: 16px;
    padding-left: 10px;
  }

  .dropdownIcon {
    margin-right: 10px;

    span:first-child {
      position: absolute;
      right: 1px;
      bottom: 0.7vh;
    }
  }
}

:global {
  :local(.dropdownPopup) {
    & .k-item.k-focus {
      & .k-link {
        outline-style: none;
      }
    }
  }
}
