$backgroundColor: #f8f8f8;

.report-page {
  display: flex;
  background-color: $backgroundColor;

  .nav-sidebar {
    width: 250px;
    background-color: #fff;

    .k-panelbar-group .k-panelbar-item,
    .k-panelbar-header {
      .k-link {
        color: initial;

        &.k-selected {
          background-color: $backgroundColor;
        }

        &:hover {
          background-color: $backgroundColor;
        }
      }
    }
  }

  .content {
    padding: 10px;
    width: 100%;
    height: calc(100vh - 50px);
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .breadcrumbs {
    margin-bottom: 10px;
  }
}
