.k-form-horizontal {
  .k-form-field {
    .k-label {
      flex-direction: row;
      justify-content: start;

      align-items: start;
      width: 15%;
    }

    .k-form-field-wrap {
      max-width: calc(100% - 15%);
    }
  }
}

.k-form-buttons {
  justify-content: center;
}

label.required {
  &:after {
    content: '*';
    color: #c93b21;
  }
}
