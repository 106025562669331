body {
    padding: 0;
    margin: 0;

    padding-right: 0 !important;
    font-family: $fontFamily;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
    height: 100%;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}