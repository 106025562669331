.icon {
  width: 100%;
  height: 100%;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;

  &.layer-Garage {
    background-image: url(./garage.svg);
  }

  &.layer-Lot {
    background-image: url(./lot.svg);
  }
}
