@import '../../../styles//variables';

.sidebar {
  display: flex;
  flex-direction: column;
  width: $sidebarWidth;
  background-color: $sidebarBackground;

  position: absolute;
  top: 0;
  bottom: 0px;
  left: 0px;

  transition: left 0.8s;

  &.collapsed {
    left: -400px;
  }

  .sidebarHeader {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;

    color: $textColorLight;

    * {
      color: $textColorLight;
    }

    h4 {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 24px;
      font-weight: 700;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline;
      padding-left: 5px;
    }

    :global(.k-button.close) {
      border: none;
    }
  }
}