@import '~@progress/kendo-theme-fluent/scss/all.scss';
@import 'react-toastify/dist/ReactToastify.css';
@import 'react-resizable/css/styles.css';

@import '~/node_modules/placeholder-loading/src/scss/placeholder-loading';
@import './placeholder';

@import './variables';

@import './kendo/styles';
@import './layout/styles';
@import './map/styles';
@import './reports/page';
@import './assets/page';
