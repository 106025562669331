.header {
  padding: 12px 10px 8px 14px;
  position: relative;
  min-height: 63px;
  background: #faf9f8;
  border-bottom: 1px solid #e5e5e5;
  color: #605e5c;

  &:global(.ph-item) {
    margin-bottom: 0;
    // border: none;
    > * {
      margin-bottom: 0;
    }
  }

  .picture {
    height: 39px;
    flex: 0.04;
    margin-bottom: 0;
  }
}
