.sliderContainer {
  width: 100%;
  display: flex;
  flex-direction: column;

  .slider {
    width: calc(100% + 8px);
    margin-left: -8px;
    margin-right: -8px;
  }
}

.legend {
  font-size: 10px;
  line-height: 10px;
  height: 10px;
  color: #201f1e;
}
