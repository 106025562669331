$fontFamily: 'Segoe UI',
Roboto,
'Helvetica Neue',
sans-serif;

$textPrimaryColor: #0078d4;
$textColor: #201f1e;
$textColorLight: #605e5c;

$sidebarWidth: 380px;
$sidebarBackground: #fff;

$headerbackground: #faf9f8;
$headerBorder: 1px solid #e5e5e5;

$spotOccupancyStatusVacancy: #00b62d;
$spotOccupancyStatusBooked: #cccc00;
$spotOccupancyStatusOccupied: red;

$navbarHeight: 50px;