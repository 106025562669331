.ph-col-3 {
  width: 25%;
}
.ph-col-4 {
  width: 33.3333333333%;
}
.ph-col-8 {
  width: 66.6666666667%;
}
.ph-col-7 {
  width: 58.333333333%;
}
