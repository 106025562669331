.button {
  &:focus,
  &:active {
    &::after,
    &::before {
      outline: none;
      background-color: transparent;
    }
  }

  > img {
    height: 14px;
    width: 14px;
    min-width: 0 !important;
    min-height: 0 !important;
  }
}
