:global {
  :local(.tabStrip) {
    .k-tabstrip-items {
      gap: 0;

      > .k-item {
        border: 1px solid #e2e2e2;

        &:hover {
          background-color: initial;
        }

        &.k-active {
          background-color: #f3f3f3;

          &::after {
            all: initial;
          }
        }

        .k-link {
          padding: 6px 14px;
        }
      }

      .k-item:first-child {
        border-right: none;
      }

      .k-item:last-child {
        border-left: none;
      }
    }

    .k-tabstrip-items-wrapper {
      .k-item::after {
        all: initial;
      }
    }

    > .k-content {
      padding: 10px 0px 0px 0px;
      overflow: hidden;
    }
  }
}
