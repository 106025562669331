.k-panelbar {
  border: none;

  .k-panelbar-group .k-panelbar-item,
  .k-panelbar-header {
    .k-link {
      background-color: initial;

      &.k-focus {
        box-shadow: none;
      }

      &.k-selected {
        font-weight: initial;

        &::before {
          all: initial;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
