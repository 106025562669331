:global {
    :local(.icon) {
        width: 100%;
        height: 100%;
        display: inline-block;
        border-radius: 50%;
        background-color: #A4A6AA;

        &.Vacant {
            background-color: #4DCD6D;
        }

        &.Occupied {
            background-color: #FF4D4D;
        }

        &.Booked {
            background-color: #DBDB4D;
        }
    }
}