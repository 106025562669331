@import '../../../styles/variables';

:global {
  :local(.expander) {
    &.k-expander {
      border-style: none;
      background-color: transparent;

      &.k-focus {
        &::after {
          outline-color: transparent;
        }
      }

      .k-expander-header {
        background-color: transparent;
      }

      .k-expander-title {
        color: $textColorLight;
      }

      .k-expander-content {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  .collapsible {
    width: 100%;
    display: block;
    border-bottom: 1px solid #edebe9;
    padding: 10px 20px 10px 20px;
  }

  .icon {
    margin-right: 7px;
    margin-top: 2px;
  }

  $buttonColor: rgb(50, 49, 48);

  h4.header {
    position: relative;
    margin: 10px 0px 10px 0px;
    line-height: 22px;
    height: 22px;

    align-content: center;

    >button {
      color: $buttonColor;
      font-size: 16px;
      height: 22px !important;

      width: 100%;
      display: flex;

      &:hover {
        color: $buttonColor;
        outline: none;
        text-decoration: none;
      }
    }
  }

  .cell {
    margin: 0px;

    font-size: 14px;
  }
}