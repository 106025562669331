.gridActions {
  display: flex;
  align-items: center;

  .button {
    border: none;
    background-color: transparent;
    padding-left: 0;
  }
}

.container {
  display: flex;
  flex-direction: column;

  .grid {
    flex: 1;
  }

  :global {
    .k-grid-header {
      background-color: #fafafa;
    }
  }
}
