:global {
  :local(.icon) {
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;

    &.Active {
      background-image: url(./active.svg);
    }

    &.Inactive {
      background-image: url(./inactive.svg);
    }

    &.Offline {
      background-image: url(./offline.svg);
    }
  }
}
