.page {
    padding: 0;
    position: relative;
    width: 100%;

    >.spinner {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}