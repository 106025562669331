:global {
  :local(.icon) {
    width: 100%;
    height: 100%;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;

    &.vendor-1 {
      background-image: url(./ips.svg);
    }

    &.vendor-2 {
      background-image: url(./flowbird.svg);
    }

    &.vendor-3 {
      background-image: url(./mackay.svg);
    }

    &.vendor-4 {
      background-image: url(./gps.svg);
    }

    &.vendor-5 {
      background-image: url(./pom.svg);
    }

    &.vendor-6 {
      background-image: url(./cale.svg);
    }

    &.vendor-7 {
      background-image: url(./duncan.svg);
    }
  }
}