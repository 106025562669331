:global {
  :local(.icon) {
    width: 100%;
    height: 100%;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;

    &.type-Parking {
      background-image: url(./parking.svg);
    }

    &.type-NoParking {
      background-image: url(./noparking.svg);
    }

    &.type-BusStop {
      background-image: url(./busstop.svg);
    }
  }
}
