:global {
  :local(.multiselect) {
    .k-input-values {
      padding-left: 4px;

      .k-input-inner {
        padding: 6px 0px;
      }
    }
  }
}
