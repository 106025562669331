.wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
}

.placeholder {
  position: absolute;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;

  background-color: white;
}
