.icon {
  width: 100%;
  height: 100%;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;

  &.policy0,
  &.policy1 {
    background-image: url(./assets/spot-general.svg);
  }

  &.policy2 {
    background-image: url(./assets/spot-commercial.svg);
  } 

  &.policy3 {
    background-image: url(./assets/spot-short-term.svg);
  }

  &.policy4 {
    background-image: url(./assets/spot-moto.svg);
  }

  &.policy5 {
    background-image: url(./assets/spot-tourbus.svg);
  } 

  &.policy6 {
    background-image: url(./assets/spot-six-wheeled.svg);
  }

  &.policy7 {
    background-image: url(./assets/spot-boat.svg);
  }

  &.policy8 {
    background-image: url(./assets/spot-disabled-people.svg);
  }

  &.policy9 {
    background-image: url(./assets/spot-passenger-loading.svg);
  }
}
