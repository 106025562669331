.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  padding: 0px;

  &::after {
    all: initial !important;
  }
}

.alertList {
  padding: 15px;
}

.alertTableContainer {
  max-height: calc(100vh - 115px);
  overflow-y: auto;
}

.alertTable {
  border-collapse: collapse;
  tbody {
    tr {
      border-bottom: 1px solid #d2d0ce;
    }

    tr:last-child {
      border-bottom: none;
    }

    td {
      padding: 5px 10px;
    }
  }
}

.icon {
  margin-right: 5px;
  max-width: 16px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background-position: center;
  position: initial;
}

.paymentIcon {
  background-image: url(./icons/loading-zone-payment.svg);
}
