:global {
    :local(.icon) {
        width: 100%;
        height: 100%;
        display: inline-block;
        background-repeat: no-repeat;
        background-size: contain;

        &.status-Active {
            background-image: url(./sign-active.svg);
        }

        &.status-Inactive {
            background-image: url(./sign-inactive.svg);
        }

        &.status-Offline {
            background-image: url(./sign-offline.svg);
        }
    }
}