:global {
  :local(.icon) {
    width: 100%;
    height: 100%;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;

    &.vendor-1 {
      background-image: url(./flowbird.svg);
    }

    &.vendor-2 {
      background-image: url(./parkonect.svg);
    }

    &.vendor-4 {
      background-image: url(./t2.svg);
    }
  }
}
