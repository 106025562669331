.form {
    display: flex;
    flex-direction: row;

    .formControl {
        flex: 5 0 70px;
        margin-right: 5px;
        width: 100%;
        border: 1px solid #ced4da;

        min-height: calc(1em + 0.5rem);
        padding: 0.2rem 0.2rem;
        font-size: 10px;
        border-radius: 1px;

        &:focus {
            outline: none;
            box-shadow: none;
        }

        &.invalid {
            border-left: 5px solid #A4262C;
        }
    }

    .btn {
        flex: 1 0 10px;
        padding: 0px;
        border: none;
        background: transparent;
        cursor: pointer;

        >i {
            font-size: 18px;
            height: 14px;
            line-height: 14px;

            &.ok {
                color: #107C10;
            }

            &.cancel {
                color: #A4262C;
            }
        }

        :not(:last-child) {
            margin-right: 5px;
        }
    }
}