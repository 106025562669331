:global {
    :local(.icon) {
        width: 100%;
        height: 100%;
        display: inline-block;
        background-repeat: no-repeat;
        background-size: contain;

        &.R7-1 {
            background-image: url(./R7-1.svg);
        }

        &.R7-2 {
            background-image: url(./R7-2.svg);
        }

        &.R7-4 {
            background-image: url(./R7-4.svg);
        }

        &.R7-6 {
            background-image: url(./R7-6.svg);
        }

        &.R7-8 {
            background-image: url(./R7-8.svg);
        }

        &.R7-108 {
            background-image: url(./R7-108.svg);
        }

        &.R7-201 {
            background-image: url(./R7-201.svg);
        }

        &.R7-203 {
            background-image: url(./R7-203.svg);
        }

        &.R8-3 {
            background-image: url(./R8-3.svg);
        }

        &.R8-3a {
            background-image: url(./R8-3A.svg);
        }
    }
}