:global {
    :local(.icon) {
        width: 100%;
        height: 100%;
        display: inline-block;
        background-repeat: no-repeat;
        background-size: contain;

        &.meter-type-SingleSpot {
            background-image: url(./single.svg);
        }
    
        &.meter-type-MultiSpot {
            background-image: url(./paystation.svg);
        }
    
        &.meter-type-DoubleSpot {
            background-image: url(./double.svg);
        }
    }
}