@import '../../../../styles/variables';

$cityWidth: 200px;

.cityName {
  cursor: default;
}

.cityDropdown {
  border: 0;
  box-shadow: none;
  width: $cityWidth !important;
  height: 28px;
  border-radius: 0;
  color: $textColor;

  :focus,
  :focus-within &::after {
    border: 0;
  }
}