@import '../variables';

.map-sidebar-row {
  margin: 10px 20px;
  color: $textColor;

  &.space-between {
    display: flex;
    justify-content: space-between;
  }

  .space-between {
    display: flex;
    justify-content: space-between;
  }
}

.map-sidebar {
  button.k-button:not(:disabled):hover {
    background-color: transparent;
  }

  .k-panelbar-header {
    .k-link {
      padding-left: 18px;
      font-size: 16px;

      &:hover {
        color: $textColorLight;
      }
    }
  }

  .k-panelbar-item {
    border-bottom: solid 1px #e5e5e5;
  }

  .k-panelbar-group {
    .k-panelbar-content {
      padding: 0 15px;
    }
  }

  .panelbar-scrollable {
    .k-panelbar-content {
      padding: 0;
      margin: 3px 3px 3px 35px;
      max-height: 220px;
      overflow-y: auto;
    }

    button {
      line-height: 22px;
    }
  }
}
