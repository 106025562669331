:global {
    :local(.icon) {
        width: 100%;
        height: 100%;
        display: inline-block;
        border-radius: 50%;
        background-color: grey;

        &.policy1 {
            background-color: #A4A6AA;
        }

        &.policy2 {
            background-color: #FFB900;
        }

        &.policy3 {
            background-color: #BAD80A;
        }

        &.policy4 {
            background-color: #000000;
        }

        &.policy5 {
            background-color: #9D750C;
        }

        &.policy6 {
            background-color: #D13438;
        }

        &.policy7 {
            background-color: #8764B8;
        }

        &.policy8 {
            background-color: #0000FF;
        }

        &.policy9 {
            background-color: #FFFFFF;
            border: 1px solid #D2D0CE;
        }
    }
}